import Api from "../apis/Api";
import ActionModalMixin from "./ActionModalMixin";

const UsersActionsMixin = {
  mixins: [ActionModalMixin],
  methods: {
    // DELETE ACTIONS
    showDeleteModal(item) {
      this.resetModal();
      this.modalOptions.title = this.$t('heading.instance.advanced.users.modal.delete.title');
      this.modalOptions.message = this.$t('heading.instance.advanced.users.modal.delete.message', {email: item.email});
      this.modalOptions.icon = "$alertwarning";
      this.modalOptions.color = "error";

      this.modalOptions.formFields = [
        {
          message: this.$t('message.confirmAction'),
          label: this.$t('form.confirmDelete.user'),
          name: "confirm",
          type: "checkbox",
          required: true,
        },
      ];

      this.modalOptions.buttons.unshift({
        label: this.$t('button.delete'),
        color: "error",
        onclick: () => {
          this.deleteUser(item);
        },
      });
      this.modalOptions.item = item;
      this.modalOptions.open = true;
    },
    deleteUser(item) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.delete(`/instances/${this.instanceId}/wordpress/users/${item.email}`)
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.instance.user.delete.success');

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });

          this.reloadItems();
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.open = false;
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },

    // CREATE ACTIONS
    showCreateModal() {
      this.resetModal();
      this.modalOptions.title = this.$t('heading.instance.advanced.users.modal.create.title');
      this.modalOptions.icon = "$edit";
      this.modalOptions.buttons.unshift({
        label: this.$t('button.create'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() && this.createUser(modal.formValues);
        },
      });
      this.modalOptions.formFields = [
        {
          label: this.$t('form.label.username'),
          name: "username",
          type: "text",
          rules: [
            (v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.username')}),
            (v) => (v && v.length <= 60) || this.$t('form.validation.maxLength', {
              field: this.$t('form.label.username'),
              length: 60
            }),
          ],
          placeholder: this.$t('form.placeholder.username'),
          tooltip: this.$t('tooltip.instance.user.field.username'),
        },
        {
          label: this.$t('form.label.email'),
          name: "email",
          type: "text",
          rules: [
            (v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.email')}),
            (v) => (v && /.+@.+\..+/.test(v)) || this.$t('form.validation.valid', {field: this.$t('form.label.email')}),
          ],
          placeholder: this.$t('form.placeholder.email'),
          tooltip: this.$t('tooltip.instance.user.field.email'),
        },
        {
          label: this.$t('form.label.displayName'),
          name: "display_name",
          type: "text",
          rules: [
            (v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.displayName')}),
            (v) => (v && v.length <= 60) || this.$t('form.validation.maxLength', {
              field: this.$t('form.label.displayName'),
              length: 60
            }),
          ],
          placeholder: this.$t('form.placeholder.displayName'),
          tooltip: this.$t('tooltip.instance.user.field.displayName'),
        },
        {
          label: this.$t('form.label.password'),
          name: "password",
          type: "password",
          rules: [
            (v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.password')}),
            (v) => (v && v.length >= 8) || this.$t('form.validation.minLength', {
              field: this.$t('form.label.password'),
              length: 8
            }),
          ],
          placeholder: this.$t('form.placeholder.password'),
          tooltip: this.$t('tooltip.instance.user.field.password'),
        },
        {
          label: this.$t('form.label.role'),
          name: "role",
          type: "select",
          tooltip: this.$t('tooltip.instance.user.field.role'),
          rules: [
            (v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.role')}),
          ],
          options: [
            { label: this.$t('form.option.administrator'), value: "administrator" },
            { label: this.$t('form.option.author'), value: "author" },
            { label: this.$t('form.option.contributor'), value: "contributor" },
            { label: this.$t('form.option.editor'), value: "editor" },
            { label: this.$t('form.option.subscriber'), value: "subscriber" },
          ],
        },
        {
          label: this.$t('form.label.sendEmail'),
          name: "send_email",
          type: "checkbox"
        },
      ];
      this.modalOptions.open = true;
    },
    createUser: function (formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.post(`/instances/${this.instanceId}/wordpress/users`, formData)
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.instance.user.create.success')

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });

          this.reloadItems();
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.open = false;
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },

    // EDIT ACTIONS
    showEditModal(item) {
      this.resetModal();
      this.modalOptions.item = item;
      this.modalOptions.title = this.$t('heading.instance.advanced.users.modal.update.title');
      this.modalOptions.icon = "$edit";
      this.modalOptions.buttons.unshift({
        label: this.$t('button.update'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() &&
            this.updateUser(item, modal.formValues);
        },
      });
      this.modalOptions.formFields = [
        {
          label: this.$t('form.label.username'),
          name: "username",
          type: "text",
          rules: [
            (v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.username')}),
            (v) => (v && v.length <= 60) || this.$t('form.validation.maxLength', {
              field: this.$t('form.label.username'),
              length: 60
            }),
          ],
          placeholder: this.$t('form.placeholder.username'),
          tooltip: this.$t('tooltip.instance.user.field.username'),
        },
        {
          label: this.$t('form.label.email'),
          name: "email",
          type: "text",
          rules: [
            (v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.email')}),
            (v) => (v && /.+@.+\..+/.test(v)) || this.$t('form.validation.valid', {field: this.$t('form.label.email')}),
          ],
          placeholder: this.$t('form.placeholder.email'),
          tooltip: this.$t('tooltip.instance.user.field.email'),
        },
        {
          label: this.$t('form.label.displayName'),
          name: "display_name",
          type: "text",
          rules: [
            (v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.displayName')}),
            (v) => (v && v.length <= 60) || this.$t('form.validation.maxLength', {
              field: this.$t('form.label.displayName'),
              length: 60
            }),
          ],
          placeholder: this.$t('form.placeholder.displayName'),
          tooltip: this.$t('tooltip.instance.user.field.displayName'),
        },
        {
          label: this.$t('form.label.role'),
          name: "role",
          type: "select",
          tooltip: this.$t('tooltip.instance.user.field.role'),
          rules: [
            (v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.role')}),
          ],
          options: [
            { label: this.$t('form.option.administrator'), value: "administrator" },
            { label: this.$t('form.option.author'), value: "author" },
            { label: this.$t('form.option.contributor'), value: "contributor" },
            { label: this.$t('form.option.editor'), value: "editor" },
            { label: this.$t('form.option.subscriber'), value: "subscriber" },
          ],
        },
      ];
      this.modalOptions.open = true;
    },
    updateUser(item, formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.put(
        `/instances/${this.instanceId}/wordpress/users/${item.id}`,
        formData
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.instance.user.update.success')
          this.reloadItems();

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.open = false;
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    showResetPasswordModal(item) {
      this.resetModal();
      this.modalOptions.title = this.$t('heading.instance.advanced.users.modal.resetPassword.title');
      this.modalOptions.message = this.$t('heading.instance.advanced.users.modal.resetPassword.message', {email: item.email});
      this.modalOptions.icon = "$alertwarning";
      this.modalOptions.color = "warning";

      this.modalOptions.formFields = [];

      this.modalOptions.buttons.unshift({
        label: this.$t('button.resetPassword'),
        color: "warning",
        onclick: () => {
          this.resetUserPassword(item);
        },
      });
      this.modalOptions.item = item;
      this.modalOptions.open = true;
    },
    resetUserPassword(item) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.put(
        `/instances/${this.instanceId}/wordpress/users/${item.id}/reset-password`,
        item
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.instance.user.resetPassword.success')

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });

          this.reloadItems();
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.open = false;
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
  },
};

export default UsersActionsMixin;
